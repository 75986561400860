* {
  box-sizing: border-box;
}

html::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}
html::-webkit-scrollbar-thumb {
  background: #434343;
  border-radius: 16px;
  box-shadow: inset 2px 2px 2px hsl(0deg 0% 100% / 25%), inset -2px -2px 2px rgb(0 0 0 / 25%);
}
html::-webkit-scrollbar-track {
  background: linear-gradient(90deg,#434343,#434343 1px,#111 0,#111);
} 

html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding:0;
}